import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { BiMap } from 'react-icons/bi';
import { emailForNewClients, supportEmail } from '../../constants';
import headerLinks from '../../constants/headerLinks';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                        <img width="50px" src="assets/img/logo/logo-white.svg" alt="logo" />
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>


                  <section>
                     <div className="p-0">
                        <div className="sidebar__tab">
                           <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" id="menu-tab" data-bs-toggle="tab" href="#menu" role="tab" aria-controls="menu" aria-selected="true">Меню</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" id="info-tab" data-bs-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">Инфо</a>
                              </li>
                           </ul>
                        </div>
                        <div className="sidebar__content">

                           <div className="tab-content" id="sidebar-tab-content">
                              <div className="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">

                                 <div className='side_navBar'>

                                    {
                                       headerLinks.map((link,index)=> {
                                          const classNames = ['about', 'iconAdd']
                                          const isLastLink = index === headerLinks.length - 1
                                          const {label,href, ...otherProps } = link

                                          if (isLastLink) {
                                             classNames.push('border-0')
                                          }

                                          const className = classNames.join(' ')

                                         return(<div className={className} key={label}>
                                          <a href={link.href} {...otherProps}>{link.label}</a>
                                      </div>)

                                       })
                                    }

                                 </div>

                              </div>

                              <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                                 <div className="sidebar__info">
                                    <div className="logo mb-40">
                                          <img src="assets/img/logo/logo-white.svg" alt="logo"/>
                                    </div>
                                    <a href="#contact" className="z-btn z-btn-white">Контакты</a>
                                    <div className="sidebar__contact mt-30">
                                       <ul>
                                          <li>
                                             <div className="icon">
                                                <i > <BiMap /> </i>
                                             </div>
                                             <div className="text">
                                                <span>Москва, улица Краснопролетарская, дом 4, позъезд 2, этаж 4</span>
                                             </div>
                                          </li>
                                          <li>
                                          <div className="text me-2 w-50">
                                          Новым клиентам
                                          </div>
                                          <div className="text theme-color w-50">
                                          <span><a href={`mailto:${emailForNewClients}`}>{emailForNewClients}</a></span>
                                          </div>
                                           </li>
                                           
                                          <li>
                                          <div className="text me-2 w-50">
                                            Поддержка
                                          </div>
                                          <div className="text theme-color w-50" >
                                          <span><a href={`mailto:${supportEmail}`}>{supportEmail}</a></span>
                                          </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;
