import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import headerLinks from '../../../constants/headerLinks';

const HomeHeader = ({ h4class, btn_text = "Войти", btn_class = "z-btn-3" }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();

  return (
    <>
      <header>
        <div className="header__area p-relative header__transparent">
          <div id="header__sticky" className={stickyMenu ? `sticky header__bottom header__bottom-2 ${h4class && h4class}`
          :`header__bottom header__bottom-2 ${h4class && h4class}`}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo-3">
                    <NavLink to="/">
                      <img src="assets/img/logo/logo-gradient.svg" alt="logo" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu main-menu-3 menu_three_wrapper">
                      <nav id="mobile-menu">
                        <ul>

                          {
                            headerLinks.map(link=>{
                              const {label,href, ...otherProps } = link
                              
                              return(<li key={label}>
                              <a href={link.href} {...otherProps}>{link.label}</a>
                              </li>)})
                          }

                        </ul>
                      </nav>
                    </div>
                    <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                      <a href="https://ord.beeline.ru/" className={`z-btn ${btn_class}`}>{btn_text}</a>
                    </div>
                    <div onClick={handleShow} className="sidebar__menu d-lg-none">
                      <div className="sidebar-toggle-btn sidebar-toggle-btn-3" id="sidebar-toggle">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
