import React from 'react';
import { emailForNewClients, telegram } from '../../constants';

const CommonCtaArea = () => {
   return (
      <>
         <section id="contact" className="cta__area pt-180 pb-155" style={{ background: `url(assets/img/cta/bg-contact.jpg)`
            , backgroundPosition: 'center', backgroundSize: 'cover' }} >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
                        <span >Понимаем, что у каждого есть вопросы, которые требуют индвидуальной проработки и готовы помочь</span>
                        <h1 >Остались вопросы? Задайте их в Telegram или по Email</h1>
                        <div className="cta__btn">
                           <a href={telegram} target="_blank" rel="noreferrer" className="z-btn z-btn-white mb-30">Telegram</a>
                           <a href={`mailto:${emailForNewClients}`} className="z-btn z-btn-transparent mb-30">Email</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonCtaArea;
