import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import HomeSingleService from '../../../components/HomeSingleService/HomeSingleService';

const HomeServices = () => {
   return (
      <>
         <section className="services__area-2 mt--250 pt-270 pb-140 p-relative" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
            <div className="container">
               <div className="row g-0">

                  <HomeSingleService image="1" title="Безопасно" subtitle="Технология соответствует всем нормам безопасности РКН и стоит в отдельном контуре от рекламных продуктов" />
                  <HomeSingleService image="2" title="Удобно" subtitle="Три варианта взаимодействия с технологией позволяют выбрать наиболее подходящий для вашего бизнеса" />
                  <HomeSingleService image="3" title="Гибко" subtitle="Мы не адаптируем рынок под свои задачи, мы адаптируемся под задачи рынка и внедряем ваши требования" />
                  <HomeSingleService image="4" title="Продуманно" subtitle="Мы предусмотрели варианты оповещения об изменениях в цепочке контрактов, что уменьшает количество ошибок" />

               </div>
               <div className="about__area-2 pt-130">
                  <div className="row">
                     <div className="col-xl-7 col-lg-6">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/icon/about.png" alt=""/>
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2 >Подключение по API</h2>
                           </div>
                           <p> - наиболее безопасный и быстрый способ маркировки креативов и передачи отчётности</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck/> </i>Стабильная работа API</span></li>
                                 <li><span><i > <FaCheck/> </i>Понятная документация</span></li>
                                 <li><span><i > <FaCheck/> </i>Больше свободы и гибкости</span></li>
                              </ul>
                           </div>
                           <a href="https://adtech.beeline.ru/xwiki/bin/view/%D0%9E%D0%A0%D0%94/API%20%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F/" rel="noreferrer" target="_blank" className="z-btn">Документация<i > <CgArrowLongRight /> </i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
