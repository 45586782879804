import React from 'react';
import { MdOutlineTimer } from 'react-icons/md';

const HomeSecondSingleService = ({leftImage, title, description, iconText, btnName, btnUrl}) => {
   return (
      <>
         <div className="services__nav-content pt-90 pb-90">
            <div className="row">
               <div className="col-xl-5 col-lg-6">
                  <div className="services__thumb text-lg-right m-img">

                     <img src={leftImage} alt="" />
                  </div>
               </div>
               <div className="col-xl-7 col-lg-6">
                  <div className="services__content-3 pl-70 pr-70">
                     <h3>{title}</h3>
                     <p>{description}</p>
                     <div className="services__icon-wrapper d-md-flex mb-35">
                        <div className="services__icon-item d-flex mr-60 mb-30">
                           <div className="icon mr-20">
                           <MdOutlineTimer/>
                           </div>
                           <div className="text">
                              <h3>{iconText}</h3>
                           </div>
                        </div>
                     </div>
                     <a href={btnUrl} target="_blank" rel="noreferrer" className="z-btn">{btnName}</a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeSecondSingleService;
