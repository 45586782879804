import React from 'react';
import Slider from "react-slick";

const HomeProjects = () => {
   // slick setting
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      fade: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 2
            }
         },
      ]
   }

   const testimonialData = [
      {
         id: 1,
         title: <h3><a href="https://sozd.duma.gov.ru/bill/1173900-7" target='_blank' rel='noreferrer'>Описание законопроекта № 1173900-7<br /> О внесении изменений в Федеральный закон "О рекламе"</a></h3>,
         img:"assets/img/project/project-1.png",
      },
      {
         id: 2,
         title: <h3><a href="https://adpass.ru/spravochnik-po-sisteme-ucheta-internet-reklamy" target='_blank' rel='noreferrer'>Справочник по системе учета<br/> интернет-рекламы в вопросах и ответах</a></h3>,
         img:"assets/img/project/project-2.svg",
      }
   ]

   return (
      <>
         <section id="project" className="project__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="section-title section__title-3 mb-70 text-center">
                        <h2>Информация об изменениях <br/> в законе "О рекламе"</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">

                     <Slider className='project__slider' {...settings}>

                        {
                           testimonialData.map((testimonial, index) => {
                              return <div key={index} className="project__item grey-bg-19">
                                 <div className="project__content">
                                    <div className="project__no">
                                       <h5>{testimonial.id}</h5>
                                    </div>
                                    {testimonial.title}
                                 </div>
                                 <div className="project__thumb m-img">
                                    <img src={testimonial.img} alt="" />
                                 </div>
                              </div>
                           })
                        }

                     </Slider>

                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeProjects;
