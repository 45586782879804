import React from 'react';
import { GiFlatPlatform } from 'react-icons/gi';
import { RiAdvertisementFill } from 'react-icons/ri';
import { SiGooglemybusiness, SiMicrodotblog } from 'react-icons/si';
import HomeSecondSingleService from '../../../components/HomeSecondSingleService/HomeSecondSingleService';

const HomeSecondServices = () => {
   return (
      <>
         <section id="services" className="services__area-3 pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6">
                     <div className="section-title section__title-3 mb-70">
                        <h2>Выберите свою роль</h2>
                        <p>а мы наглядно покажем, как легко начать подавать данные в ОРД</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                        <ul className="nav nav-pills " id="services-tab" role="tablist">
                           <li className="nav-item mb-45">
                              <a className="nav-link active" id="rd-tab" data-bs-toggle="pill" href="#rd" role="tab" aria-controls="rd" aria-selected="true">
                                 <i ><SiGooglemybusiness/></i>Рекламодатель
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="ra-tab" data-bs-toggle="pill" href="#ra" role="tab" aria-controls="ra" aria-selected="true">
                                 <i ><RiAdvertisementFill/></i> Агентство
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="rs-tab" data-bs-toggle="pill" href="#rs" role="tab" aria-controls="rs" aria-selected="true">
                                 <i ><GiFlatPlatform/></i> Рекламная система
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="rr-tab" data-bs-toggle="pill" href="#rr" role="tab" aria-controls="rr" aria-selected="true">
                                 <i ><SiMicrodotblog/></i> Площадка/Блогер
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="services__tab grey-bg-18">
                        <div className="tab-content" id="services-content">
                           <div className="tab-pane fade show active" id="rd" role="tabpanel" aria-labelledby="rd-tab">
                              <HomeSecondSingleService leftImage="assets/img/services/rd.png" title="Инструкция для рекламодателя" description="Из презентации вы узнаете о том, что вы сможете видеть в своем кабинете рекламодателя в ЕРИР, узнаете о новых рисках и вариантах их митигирования, увидите наглядно что и куда загружать" iconText="На ознакомление потребуется 60 минут" btnUrl="https://1ord.ru/assets/workwith1ord.pdf" btnName="Инструкция для рекламодателя" />
                           </div>
                           <div className="tab-pane fade" id="ra" role="tabpanel" aria-labelledby="ra-tab">
                              <HomeSecondSingleService leftImage="assets/img/services/ra.png" title="Инструкция для рекламного агентства" description="Из инструкции вы узнаете на что обратить внимание при согласии на принятие ответственности за подачу информации в ОРД за клиента, можете ли вы делегировать эту ответственность, риски и их митигация" iconText="На ознакомление потребуется 90 минут" btnUrl="https://1ord.ru/assets/workwith1ord.pdf" btnName="Рекламному агентству" />
                           </div>
                           <div className="tab-pane fade" id="rs" role="tabpanel" aria-labelledby="rs-tab">
                              <HomeSecondSingleService leftImage="assets/img/services/rs.png" title="Инструкция для рекламных систем" description="Инструкция представляет собой описание целей и задач других ролей, которые вы можете решить при помощи технической интеграции по API" iconText="На ознакомление потрбуется около 6-ти часов" btnUrl="https://1ord.ru/assets/workwith1ord.pdf" btnName="Рекламной системе" />
                           </div>
                           <div className="tab-pane fade" id="rr" role="tabpanel" aria-labelledby="rr-tab">
                              <HomeSecondSingleService leftImage="assets/img/services/rr.png" title="Инструкция для рекламораспространителей" description="Инструкция содержит пошаговый план для создания акта разаллокации, варианты делегирования ответственности и принципы маркировки статей/ссылок/постов" iconText="На ознакомление потребуется 90 минут" btnUrl="https://1ord.ru/assets/workwith1ord.pdf" btnName="Площадке/Блогеру"/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSecondServices;
