import React from 'react';

const HomeHeroSection = () => {
   return (
      <>
         <section className="hero__area p-relative">
            <div className="hero__item hero__height d-flex align-items-center">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6 col-lg-5 order-last">
                              <img src="assets/img/slider/03/intro.png" alt="" />
                     </div>
                     <div className="col-xl-6 col-lg-7 d-flex align-items-center">
                        <div className="hero__content">
                           <span>Законопроектом <a href="https://sozd.duma.gov.ru/bill/1173900-7" target="_blank" rel="noreferrer">№ 1173900-7</a> внесены изменения Федеральный в закон «О рекламе» и с 01.09.2022 рекламодатели и рекламораспространители обязуются регистрировать рекламные креативы и отчитываться о результатах рекламных кампаний в ОРД.</span>
                           <h1>Первый оператор<br /> рекламных данных</h1>
                           <p>Сервис для автоматической передачи данных о рекламе в ЕРИР</p>
                           <a href="#services" className="z-btn z-btn-border">Как начать?</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeHeroSection;
