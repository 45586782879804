import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeFaq from './HomeFaq/HomeFaq';
import HomeFooter from './HomeFooter/HomeFooter';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeHeroSection from './HomeHeroSection/HomeHeroSection';
import HomeProjects from './HomeProjects/HomeProjects';
import HomeSecondServices from './HomeSecondServices/HomeSecondServices';
import HomeServices from './HomeServices/HomeServices';

const Home = () => {
   return (
      <>
         <PageHelmet pageTitle="Оператор рекламных данных" />

         <HomeHeader />
         <HomeHeroSection />
         <HomeServices />
         <HomeSecondServices />
         <HomeProjects />
         <HomeFaq />
         <CommonCtaArea />
         <HomeFooter />
      </>
   );
};

export default Home;
